import { Routes } from '@angular/router';
import { AuthGuard } from './services/authentication/auth.guard';

export const routes: Routes = [
  // {
  //   path: 'intro',
  //   loadComponent: () => import('./pages/intro/intro.page').then(m => m.IntroPage),
  // },
  {
    path: 'intro',
    loadComponent: () => import('./pages/intro/intro.page').then(m => m.IntroPage)
  },
  {
    path: 'share',
    loadComponent: () => import('./pages/share/share.page').then(m => m.SharePage)
  },
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.routes').then((m) => m.routes),
  },
  {
    path: 'profile-interviews',
    loadComponent: () => import('./pages/profile-interviews/profile-interviews.page').then( m => m.ProfileInterviewsPage)
  },
];
