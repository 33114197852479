import { isPlatform } from '@ionic/angular';
import config from '../../../../capacitor.config';
import { environment } from 'src/environments/environment';

export const domain = 'auth.meu.fan';
export const clientId = 'Zy9E6QJ2NKyVFUp11H4Hu604LgN2VaGr';
const { appId } = config;

// Use `auth0Domain` in string interpolation below so that it doesn't
const auth0Domain = domain;
const iosOrAndroid = isPlatform('hybrid');

export const callbackUri = iosOrAndroid ? `${appId}://${auth0Domain}/capacitor/${appId}/callback` : environment.url;
