import { CapacitorConfig } from '@capacitor/cli';

const config: CapacitorConfig = {
  appId: 'fan.meu',
  appName: 'meufan',
  webDir: 'www/browser',
  server: {
    androidScheme: 'https'
  },
  ios: {
    handleApplicationNotifications: false
  }
};

export default config;
