import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, Router, provideRouter } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { registerLocaleData } from '@angular/common';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers, Storage } from '@ionic/storage';
import { MomentModule } from 'ngx-moment';

import localePt from '@angular/common/locales/pt';
import { provideServiceWorker } from '@angular/service-worker';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './app/services/authentication/auth.guard';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import * as Sentry from "@sentry/capacitor";
import * as SentryAngular from "@sentry/angular";

import { AuthModule } from '@auth0/auth0-angular';
import { domain, clientId, callbackUri } from './../src/app/services/authentication/auth.config';


const redirect_uri = `fan.meu://dev-iqqufwwdsr2cs2op.us.auth0.com/capacitor/fan.meu/callback`;

if (environment.production) {
  Sentry.init({
    dsn: "https://8cae018b53dc0f107ad35afc2c188dd9@o246479.ingest.us.sentry.io/4508320533970944",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: ["localhost", /^https:\/\/api\.meu\.fan/],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localePt);
defineCustomElements(window);


if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: "pt" },
    provideIonicAngular({
      rippleEffect: false,
      mode: 'md'
    }),
    {
      provide: ErrorHandler,
      // Attach the Sentry ErrorHandler
      useValue: SentryAngular.createErrorHandler(),
    },
    {
      provide: SentryAngular.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [SentryAngular.TraceService],
      multi: true,
    },
    AuthGuard,
    importProvidersFrom(
      AuthModule.forRoot({
        domain: domain,
        clientId: clientId,
        useRefreshTokens: true,
        useRefreshTokensFallback: false,
        //cacheLocation: 'localstorage',
        authorizationParams: {
          callbackUri,
        },
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      MomentModule,
      HttpClientModule,
      IonicStorageModule.forRoot({
        name: '__meufan',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
      })
    ),
    provideRouter(routes),
  ],
  // provideServiceWorker('ngsw-worker.js', {
  //   enabled: !isDevMode(),
  //   registrationStrategy: 'registerWhenStable:30000'
  // })
});
